import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div>Page Not Found</div>;
  </Layout>
);

export default NotFoundPage;
