/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';
import '../styles/tailwind.css';

const Layout = ({ children, footer = true, bodyMargin = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div
      className="flex flex-col"
      style={{ minHeight: '100vh', maxHeight: '100vh' }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="flex flex-grow flex-col">
        <main className={`flex-grow ${bodyMargin && 'mx-6 my-5'}`}>
          {children}
        </main>
        {footer && (
          <footer>
            © {new Date().getFullYear()}, Built with
            <a href="https://www.gatsbyjs.org">Gatsby</a>
            <a href="https://news.ycombinator.com/item?id=32480934">
              Inspired by this thread
            </a>
          </footer>
        )}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
  bodyMargin: PropTypes.bool,
};

Layout.defaultProps = {
  footer: true,
  bodyMargin: true,
};

export default Layout;
